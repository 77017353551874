body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.main-container {
    margin-top: 100px;
}

.fa-microphone {
    color: #14A76C;
}

    .fa-microphone:hover {
        color: #0c6e47;
    }

.fa-file-audio {
    color: #FF652F;
}

    .fa-file-audio:hover {
        color: #d44916;
    }

.output-display {
    background-color: #383b4b;
    height: 700px;
}

.fas, .fa {
    cursor: pointer;
}

.background {
    background-color: #272727;
    color: #747474;
}

.chat-messages {
    text-align: left;
    overflow: auto;
    padding-left: 30px;
    height: calc(100vh - 200px);
}

    .chat-messages .lists-container {
        font-size: 16px;
    }

@keyframes pulse {
    0% {
        color: var(--primary-color);
    }

    50% {
        color: var(--secondary-color);
    }

    100% {
        color: var(--primary-color);
    }
}

.message-search .search-running-text {
    -webkit-animation: opacity_change 2s ease-in-out infinite alternate;
    -moz-animation: opacity_change 2s ease-in-out infinite alternate;
    -ms-animation: opacity_change 2s ease-in-out infinite alternate;
    -o-animation: opacity_change 2s ease-in-out infinite alternate;
    animation: opacity_change 2s ease-in-out infinite alternate;
    font-weight: bold;
    font-size: 16px;
}

.message-search .search-complete-text {
    font-weight: normal;
    font-size: 16px;
}


/*    .message-search .search-running-text {
      animation: opacity_change 2s ease-in-out infinite alternate;
      font-weight: bold;
    }*/
@keyframes opacity_change {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.2;
    }
}


.ant-layout-sider-children {
    padding-top: 10px !important;
    padding-left: 10px;
}

h1 {
    font-size: medium;
    font-weight: normal;
}

.user-detail {
    float: right;
    margin-top: 5px;
}

    .user-detail .ant-menu {
        font-size: 16px;
    }


.ant-tabs-tab-btn {
    color: white;
}

.ant-tabs-tab-active div {
    color: #180A48 !important;
    /*color: #180A48;*/
}

.ant-menu {
    background-color: transparent !important;
}

.ant-menu-item {
    text-transform: capitalize;
}

.imgs-container {
    margin-top: 5px;
}

    .imgs-container div {
        margin-top: 5px;
        display: flex;
        flex-wrap: wrap;
    }

.image-backdrop {
    background-color: #C9C0D3;
    width: 212px;
    height: 212px;    
    border-radius: 10%;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-btn-primary {
    background-color: #68368B;
}

    .ant-btn-primary:hover {
        background-color: #58317E;
    }

.search-running {
    margin-left: 50px;
    font-size: medium;
    color: black;
    animation: GFG 4s infinite linear;
}

.search-running-text {
    color: black;
    margin-left: 10px;
    margin-top: 15px;
}

@keyframes GFG {
    0% {
        transform: rotate(0deg) translateX(10px) rotate(0deg);
    }

    100% {
        transform: rotate(-360deg) translateX(10px) rotate(360deg);
    }
}

span.ant-spin-dot {
    /*height:2em !important;*/
}

.ant-spin-text {
    background-color: white;
    font-size: 30px !important;
    border-width: 1px;
    border-color: lightgray;
    border-radius: 10px;
    margin-top: 30px;
    margin-left: 10px;
    /*   width:100px;
    height:40px;*/
}

label {
    color: black;
}

.toggleSound {
    padding-top: 10px;
}

.toggleCommand {
    padding-top: 10px;
}

.command-strip-control-container {
    padding-left: 10px;
}

/*.sound-checkbox .ant-checkbox-inner::after {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url('path/to/your/icon.svg');
}*/

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
}

.persona-configuration {
    margin-left: 25%;
    position: fixed;
    left: 0px;
    width: 50vw !important;
}

.data-configuration-section {
    background-color: transparent;
    color: white;
}

.ant-upload-list-item {
    color: white;
}

/*.chat-cost {
    color: darkgray;
    height: 1em;
    float: left;
}*/

.chat-cost {
    color: darkgray;
    height: 1em;
    float: left;
    position: relative;
    top: -30px;
    left: 20px;
    margin-right:40px;
}

.audio-progress {
    float: left;
    position: relative;
    height: 1em;
    top: -30px;
    color: darkgray;
    /*display: flex;*/
    /*align-items: center;*/
    margin-right: 10px;
    font-size: 14px;
}  

.chat-id {
    color: darkgray;
    height: 1em;
    float: left;
    position: relative;
    top: -15px;
    left: 20px;
}


.costs-section {
    color: white;
}

.side-panel {
    height: calc(100vh - 75px);
    transition: none !important;
}

.tabs {
    display: flex;
    flex-direction: column;
    height: 0px;
    margin: 0;
}

.chat-menu {
    height: calc(100vh - 200px);
    overflow-y: auto;
}

.message-spinner {
}

.ADGroupSelect {
    display: block;
    width: 300px;
}

/*centre horizontally and vertically*/
.LoginPrompt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
}

.ant-upload-list-item-name {
    color: black;
}

.ant-modal-body {
    height: 70vh;
    overflow-y: auto;
}

.personas-list {
    display: flex;
}


.agent-card .ant-avatar {
    width: 70px !important;
    height: 70px !important;
}

.left-side-button {
    background-color: #74747433;
    width: 60px;
    height: 60px;
    border-radius: 10%;
}

    /*.chat-delete {
    margin-top:200px;
}

.chat-add {
    margin-top:150px;    
}
*/

    .left-side-button button:disabled {
        color: black;
    }

    .left-side-button button:disabled {
        color: lightgrey;
    }

    .left-side-button:hover {
        background-color: #74747466;
    }

.left-vertical-control-bar {
    margin-top: 100px;
    float: left;
}

    .left-vertical-control-bar div {
        margin-top: 10px;
    }

    .left-vertical-control-bar button {
        width: 60px;
        height: 60px;
    }


    .left-vertical-control-bar .stop-playback {
        margin-top: 50px;
    }

.ant-popconfirm-buttons button.ant-btn {
    font-size: 20px;
    width: 100px;
    height: 50px;
}

.userInput {
    margin-left: 10px;
}

.userInput-desktop input.ant-input {
    height: 50px;
}

.userInput-iPad input.ant-input {
    height: 57px;
}

.userInput input.ant-input {        
    font-size: 20px;
}

.userInput-desktop .ant-input-group-addon button {
    height: 66px;
    width: 150px;
}

.userInput-iPad .ant-input-group-addon button {
    height: 57px;
    width: 150px;
}

.speak-button {
    height: 50px;
    width: 100px;
}

.upload-img-button {
    
 
}

.upload-img-button .ant-upload button {
    height: 50px;
    width: 100px;
}


span.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left {
    top: 0px;
    margin-top: 0px;
    height: 60px;
    width: 60px;
    inset-inline-end: -60px;
}


.debugConsole {
    height: 400px;
    width: 100%;
    background-color: #272727;
    color: white;
    position: absolute;
    top: 0px;
    left: 70px;
    right: 0px;
    bottom: 100px;
    overflow-y:auto;
}

    .debugConsole .ant-list-item {
        color: white;
    }

.waiting-spinner svg {
    font-size: 50px;
    color: #68368B;
}